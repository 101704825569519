// baseURL
export const apiRegistration = '/auth/register/'
export const apiRegistrationSeller = '/auth/register-seller/'
export const apiVerifyRegistration = '/auth/verify-registration/'

export const apiLogin = '/auth/login/'
export const apiLogout = '/auth/logout/'
export const apiToken = '/api/token/'
export const apiRefreshToken = '/api/token/refresh/'
export const apiProfile = '/auth/profile/'
export const apiSendResetPasswordLink = '/auth/send-reset-password-link/'
export const apiResetPassword = '/auth/reset-password/'
export const apiChangePassword = '/auth/change-password/'

export const apiChatusers = '/api/chatusers/'
export const apiChatUserSessions = '/api/chatsessions/users_sessions/'
export const apiChannelsAll = '/api/channels/all/'

export const apiLayoutList = '/api/layouts/'
export const apiFavoritesLayoutList = '/api/favorites_layouts/'
export const apiLayoutAdditionRequest = '/api/layouts/addition_request/'

export const apiLikesList = '/api/likes/'
export const apiDiscounts = '/api/discounts/'

export const apiCentrifugoSubscribe = '/centrifugo/subscribe/'
export const apiCentrifugoChatPublish = '/centrifugo/chats/publish/'
export const apiCentrifugoChatRestore = '/centrifugo/chats/restore/'

export const apiCompanyAdditionRequest = '/api/company/addition_request/'

export const apiMetricsBase = "/api/metrics/base/"
export const apiExtLinkLogs = 'api/ext-link-logs/create_and_redirect/'

// baseCentrifugoURL
export const apiCentrifugoHistory = '/api/history'
export const apiNews = '/wagtail/api/v2/'
export const apiPagesNews = '/wagtail/pages/news/'
